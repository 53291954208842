<template>
	<div class="section_right">
		<!--section_right-->
		<div class="sub_top">
			<!--sub_top-->
			<div class="sub_title" v-if="img !=''">
                <img :src="img" :alt="symbol" width="26">{{symbol}} {{$t("coin.2")}}
            </div>
		</div>
		<!--sub_top end-->
		<div class="section_right_inner">
			<!--section_right_inner-->
			<div class="s_left">
                <div class="history_box">
                    <ul class="pl-0">
                        <li class="d-flex align-items-center mb-2 rounded-3 px-3 py-2" v-for="(item, index) in list" :key="index">                            
                            <span class="txt_icon" 
                                :class="{'send' : item.type == 'W', 
                                'receive' : item.type == 'D' }">

                                <i class="far fa-ellipsis-h" v-if="item.hash===null"></i>
                                <i class="far fa-check" v-else-if="item.hash!==null"></i>
                                <i class="far fa-times" v-else></i>

                            </span>

                            <div class="left">
                                <p class="addr fs-px-13 fw-bold text-gray-relative-900" v-if="item.type == 'W'">{{typeName(item.type)}}</p>
                                <p class="addr fs-px-13 fw-bold text-gray-relative-900" v-else>{{typeName(item.type)}}</p>
                                <p class="fs-px-12 text-gray-500">{{item.create_time}}</p>
                            </div>
                            <div class="right">
                                <p class="text-gray-relative-900"><b>{{item.amount}}</b> {{item.symbol}}</p>
                                <p class="fs-px-12 text-gray-500">0 $</p>
                            </div>
                        </li>
                    </ul>
                </div>
			</div>
            <div class="position-absolute start-0 bottom_bar w-100">
                <div class="flex-between-center gap-2 w-100 px-3 py-3">
                    <button class="border-0 w-100 fs-px-14 py-2 rounded" v-if="net =='TRX'"><a :href="`https://tronscan.org/#/address/${address}`" target="_new" class="text-white"><i class="arr_icon far fa-arrow-up me-2"></i> {{$t("coin.2")}}</a></button>
                    <button class="border-0 w-100 fs-px-14 py-2 rounded" v-if="net =='BNB'"><a :href="`https://bscscan.com/address/${address}`" target="_new"  class="text-white"><i class="arr_icon far fa-arrow-up me-2"></i> {{$t("coin.2")}}</a></button>
                </div>
                <div class="flex-between-center gap-2 w-100 px-3 py-3">
                    <button class="border-0 w-100 fs-px-14 py-2 rounded" @click="$router.push(`/${$i18n.locale}/CoinSend/${symbol}`)"><i class="arr_icon far fa-arrow-up me-2"></i> {{$t("coin.3")}}</button>
                    <button class="border-0 w-100 fs-px-14 py-2 rounded" @click="$router.push(`/${$i18n.locale}/CoinReceive/${symbol}`)"><i class="arr_icon far fa-arrow-down me-2"></i> {{$t("coin.4")}}</button>
                </div>
            </div>
		</div>
		<!--section_right_inner end-->
	</div>
	<!--section_right end-->
</template>
<script>
const CryptoJS = require("crypto-js");
	export default {
		components: {
		},
		data(){
			return{
                img : '',
                symbol: this.$route.params.symbol,
                list: [],
                net : '',
                address : '',
			}
		},
	    mounted(){
            this.GetCoinDetailInfo();
            // this.GetCoinHistory();
		},
		methods : {
            GetCoinDetailInfo(){
                const symbol = this.symbol;
                const body = {symbol};
                const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
                this.$http.post(`/member/coin/GetCoinDetailInfo`,{req}).then(
                    res => {
                        if(res.status==200){
                            if(res.data.code=='200'){
                                const e_body = res.data.body;
                                const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                                const d_res = bytes.toString(CryptoJS.enc.Utf8);
                                const body = JSON.parse(d_res)
                                this.img = body.info.img;
                                this.name = body.info.name;
                                this.net  = body.info.net;
                                this.address = body.address;
                            }else if(res.data.code=='9999'){
                                this.$store.dispatch('SETLOGOUT').then(
                                    ()=>{
                                        window.location.href= `/${this.$i18n.locale}/login`;
                                    }
                                )
                            }
                        }
                    }
                ).catch(() => {});
            },
            GetCoinHistory(){
                const symbol = this.symbol;
                const body = {symbol};
                const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
                this.$http.post(`/member/coin/GetHistory`,{req}).then(
                    res => {
                        if(res.status==200){
                            if(res.data.code=='200'){
                                const e_body = res.data.body;
                                const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                                const d_res = bytes.toString(CryptoJS.enc.Utf8);
                                const body = JSON.parse(d_res)
                                
                            }else if(res.data.code=='9999'){
                                this.$store.dispatch('SETLOGOUT').then(
                                    ()=>{
                                        window.location.href= `/${this.$i18n.locale}/login`;
                                    }
                                )
                            }
                        }
                    }
                ).catch(() => {});
            },
            typeName: function(val){
                if(val == 'W') return this.$t("coin.3")
                else if(val == 'D') return this.$t("coin.4")
            },
		}
	}
</script>
<style scoped>

.txt_icon{
  display: block;
  width: 30px;
  height: 30px;
  background-color: #2d8dc9;
  color: #fff;
  text-align: center;
  flex-shrink: 0;
  border-radius: 100%;
  font-size: 12px;
  line-height: 30px;
  margin-right: 10px;
}
.txt_icon.send{
  background-color: #7259c4;
}

.txt_icon.receive{
  background-color: #bdbdbd;
}

.history_box{
  /* font-size: 14px; */
  font-size: 0.9em;
}
.history_box p{
  margin-bottom: 0;
}
.history_box .left{
  width: 50%;
}
.history_box .right{
  width: calc(50% - 40px);
  text-align: right;
  font-weight: 500;
}
.history_box .addr{
  text-overflow: ellipsis;
  white-space: pre;
  overflow: hidden;
}

.bottom_bar{
    bottom: 73px;
}

.theme03 .bottom_bar{
    background-color: #eef1f9; 
}

.bottom_bar button{
    color: #fff
}
.bottom_bar button:nth-child(1){
    background-color: #7259c4;
}
.bottom_bar button:nth-child(2){
    background-color: #616161;
}
</style>