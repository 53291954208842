<template>
	<div class="section_right">
		<div class="sub_top">
			<div class="sub_title" v-if="img !=''">
                <img :src="img" :alt="symbol" width="24">{{symbol}} {{$t('coin.3')}}
            </div>
		</div>
		<div class="section_right_inner">
            <div class="mypage_section01" v-if="step ==1">
                <div class="mypage_input_box">
                    <div >
                        <div class="m-0 w-100">
                            <div class="form-group line mb-4" v-if="contract !=''">
                                <label class="fs-px-14 font-weight-bold pb-2">{{main_coin}} {{$t('coin.7')}}</label>
                                <input type="number" class="form-control" v-model ="main_balance" :placeholder="$t('coin.7')" disabled>
                            </div>
                            <div class="form-group line mb-4">
                                <label class="fs-px-14 font-weight-bold pb-2">{{$t('coin.7')}}</label>
                                <input type="number" class="form-control" v-model ="balance" :placeholder="$t('coin.7')" disabled>
                            </div>
                            <div class="form-group line mb-4">
                                <label class="fs-px-14 font-weight-bold pb-2">{{$t('coin.6')}}</label>
                                <div class="input-group">
                                    <input type="text" class="form-control" v-model="address" placeholder="Address">
                                </div>
                            </div>
                            
                            <div class="form-group line mb-2">
                                <label class="fs-px-14 font-weight-bold pb-2">{{$t('coin.8')}}</label>
                                <input type="number" class="form-control" v-model="w_balance" placeholder="0">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mypage_section01"  v-if="step ==2">
                <div class="mypage_input_box">
                    <div >
                        <div class="m-0 w-100">
                            <div class="text-end">
                                <div class="flex-between-center mb-2">
                                    <label class="fs-px-14 font-weight-bold pb-2">{{$t('coin.7')}}</label>
                                    <p>{{ balance }}</p>
                                </div>
                            </div>
                            <div class="text-end">
                                <div class="flex-between-center mb-2">
                                    <label class="fs-px-14 font-weight-bold pb-2">{{$t('coin.6')}}</label>
                                    <p>{{ address }}</p>
                                </div>
                            </div>
                            <div class="text-end">
                                <div class="flex-between-center mb-2">
                                    <label class="fs-px-14 font-weight-bold pb-2">{{$t('transfer.7')}}</label>
                                    <p>{{ w_balance }}</p>
                                </div>
                            </div>
                            <hr class="my-4">
                            <div class="text-end">
                                <div class="flex-between-center mb-2">
                                    <label class="fs-px-14 font-weight-bold pb-2">{{$t('coin.9')}}</label>
                                    <p>{{ gas_price }} {{net}}</p>
                                </div>
                            </div>
                            <hr class="my-4">
                            <div class="form-group line mb-2">
                                <label class="fs-px-14 font-weight-bold pb-2">{{$t('transfer.7')}}</label>
                                <input type="password" class="form-control" v-model="otp_code">
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex py-3 align-items-center bottom_bar ">
                <button class="w-100 btn btn-primary mt-0 py-2" @click="CheckSend()" v-if="step ==1">{{$t('coin.12')}}</button>
                <button class="w-100 btn btn-primary mt-0 py-2" @click="SendConfirm()" v-if="step ==2">{{ $t('coin.13') }}</button>
            </div>
		</div>
	</div>
</template>
<script>
const CryptoJS = require("crypto-js");
const exactMath = require('exact-math');
export default {
    components: {
        
    },
    data(){
        return{
            step : 1,
            balance : 0,
            w_balance : '',
            contract : '',
            main_coin : '',
            main_balance : '',
            address:'',
            img:'',
            symbol: this.$route.params.symbol,
            otp_code : '',
            gas_price : '',
            net : '',

            
        }
    },
    mounted(){
        this.CheckOTP();
        this.GetCoinInfo()
        this.GetCoinBalance();
    },
    methods : {
        CheckOTP(){
				
            const body = {};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

            this.$http.post(`/member/withdraw/CheckOTP`,{req}).then(
                res => {
                    if(res.status==200){
                        if(res.data.code=='200'){
                            const e_body = res.data.body;
                            const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                            const d_res = bytes.toString(CryptoJS.enc.Utf8);
                            const body = JSON.parse(d_res)

                            this.otp_able = body.otp_able;
                            
                            if(body.otp_able =='N'){
                                this.$router.push({path:`/${this.$i18n.locale}/otp`})
                            }

                        }else if(res.data.code=='9999'){
                            this.$store.dispatch('SETLOGOUT').then(
                                ()=>{
                                    window.location.href= `/${this.$i18n.locale}/login`;
                                }
                            )
                        }
                    }

                }
            ).catch(() => {});
        },
        GetCoinInfo(){
            const symbol = this.symbol;
            const body = {symbol};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            this.$http.post(`/member/coin/GetCoinInfo`,{req}).then(
                res => {
                    if(res.status==200){
                        if(res.data.code=='200'){
                            const e_body = res.data.body;
                            const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                            const d_res = bytes.toString(CryptoJS.enc.Utf8);
                            const body = JSON.parse(d_res)
                            this.img = body.info.img;
                            this.name = body.info.name;
                        }else if(res.data.code=='9999'){
                            this.$store.dispatch('SETLOGOUT').then(
                                ()=>{
                                    window.location.href= `/${this.$i18n.locale}/login`;
                                }
                            )
                        }
                    }
                }
            ).catch(() => {});
        },
        
        GetCoinBalance(){
            const symbol = this.symbol;
            const body = {symbol};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            this.$http.post(`/member/coin/GetCoinBalanceDetail`,{req}).then(
                res => {
                    if(res.status==200){
                        if(res.data.code=='200'){
                            const e_body = res.data.body;
                            const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                            const d_res = bytes.toString(CryptoJS.enc.Utf8);
                            const body = JSON.parse(d_res)
                            this.main_balance = body.main_balance;
                            this.balance = body.balance;
                            this.contract  = body.contract;
                        }else if(res.data.code=='9999'){
                            this.$store.dispatch('SETLOGOUT').then(
                                ()=>{
                                    window.location.href= `/${this.$i18n.locale}/login`;
                                }
                            )
                        }
                    }
                }
            ).catch(() => {});
        },
        CheckSend(){
            const symbol = this.symbol;
            const w_balance = this.w_balance;
            const address = this.address;
            const balance  = this.balance;

            if(w_balance == ''){
                this.$alert(this.$t('coin.14'))
                return false;
            }

            const c_balance = exactMath.sub(balance,w_balance);

            if(c_balance < 0){
                this.$alert(this.$t('coin.15'))
                return false;
            }

            const body = {symbol,w_balance,address};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            let loader = this.$loading.show({
				loader: 'dots',color:'#ff0073'
			});
            this.$http.post(`/member/coin/CheckSend`,{req}).then(
                res => {
                    if(res.status==200){
                        loader.hide();
                        if(res.data.code=='200'){
                            const e_body = res.data.body;
                            const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                            const d_res = bytes.toString(CryptoJS.enc.Utf8);
                            const body = JSON.parse(d_res);
                            this.gas_price = body.fee;
                            this.net = body.net;
                            this.step =2;
                        }else if(res.data.code=='300'){
                            this.$alert(this.$t('coin.16'))
                        }else if(res.data.code=='400'){
                            this.$alert(this.$t('coin.17'))
                        }else if(res.data.code=='110'){
                            this.$alert(this.$t('coin.18'))
                        }else if(res.data.code=='120'){
                            this.$alert(this.$t('coin.19'))
                        }else if(res.data.code=='9999'){
                            this.$store.dispatch('SETLOGOUT').then(
                                ()=>{
                                    window.location.href= `/${this.$i18n.locale}/login`;
                                }
                            )
                        }
                    }
                }
            ).catch(() => {});
            
        },
        SendConfirm(){
            const symbol = this.symbol;
            const w_balance = this.w_balance;
            const address = this.address;
            const otp_code = this.otp_code;

            if(otp_code ==''){
                this.$alert(this.$t('coin.20'))
                return false;
            }
            const body = {symbol,w_balance,address,otp_code};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            let loader = this.$loading.show({
				loader: 'dots',color:'#ff0073'
			});
            this.$http.post(`/member/coin/SendConfirm`,{req}).then(
                res => {
                    if(res.status==200){
                        loader.hide();
                        if(res.data.code=='200'){
                            this.$alert(this.$t('coin.21')).then(
                                ()=>{
                                    this.$router.push({path : `/${this.$i18n.locale}/CoinHistory/${this.symbol}`})
                                }
                            )
                        }else if(res.data.code=='500'){
                            this.$alert(this.$t('coin.22'))
                        }else if(res.data.code=='300'){
                            this.$alert(this.$t('coin.16'))
                        }else if(res.data.code=='400'){
                            this.$alert(this.$t('coin.17'))
                        }else if(res.data.code=='110'){
                            this.$alert(this.$t('coin.18'))
                        }else if(res.data.code=='120'){
                            this.$alert(this.$t('coin.19'))
                        }else if(res.data.code=='9999'){
                            this.$store.dispatch('SETLOGOUT').then(
                                ()=>{
                                    window.location.href= `/${this.$i18n.locale}/login`;
                                }
                            )
                        }
                    }
                }
            ).catch(() => {});
            
        },

    }
}
</script>
<style>
</style>