<template>
    <div class="section_right">
        <div class="sub_top">
            <div class="sub_title flex-between-center flex-wrap">
				<div><i class="far fa-history text-primary"></i>{{$t("coin.1")}}</div>
				
				<div class="d-flex">
					<div class="flex-start-center fs-px-14 exchange_btn btn-primary px-3 rounded-pill me-3 cursor-pointer" @click="GotoPackagePage()">
						<i class="far fa-cubes me-1"></i>
						<small>{{$t('assets.5')}}</small>
					</div>	
					<div class="flex-start-center fs-px-14 exchange_btn btn-primary px-3 rounded-pill cursor-pointer" @click="GotoExchangePage()">
						<i class="far fa-exchange me-1"></i>
						<small>{{$t('assets.6')}}</small>
					</div>
				</div>
			</div>
        </div>
		<div class="section_right_inner">
			<div class="s_left">
                <ul>
                    <li v-for="(item,index) in list" :key="index" class="mb-2 shadow-sm">
                        <div  class="bg-gray-relative-0 w-100 flex-start-center p-3 rounded cursor-pointer" @click="GotoMove(item.symbol)">
                            <div class="me-3 rounded-circle">
                                <img :src="item.img" :alt="item.name" width="42">
                            </div>
                            <div class="flex-between-center w-100">
                                <div class="lh-1">
                                    <h6 class="fw-bold text-gray-relative-900">{{ item.name }}</h6>
                                    <small class="fs-px-12 text-gray-500">= {{ CheckBalance(item.balance,item.rate) }} USD</small>
                                </div>
                                <div class="lh-1 text-end">
                                    <h6 class="fw-bold text-gray-relative-900">{{ item.balance }} {{ item.symbol }}</h6>
                                    <small class="fs-px-12 text-gray-500">{{item.symbol}} 1 = {{item.rate}} USD</small>
                                </div>
                            </div>
                        </div>
                        <!-- <router-link :to=""> -->
                            
                        <!-- </router-link> -->
                    </li>
                </ul>
			</div>
		</div>
	</div>
</template>


<script>
const CryptoJS = require("crypto-js");
const exactMath = require('exact-math');
	export default {
		components: {
		},
		data(){
			return{
                rate: 0,
                list: []
			}
		},
	    mounted(){
            this.GetCoinList()
		},
		methods : {
            GotoMove(symbol){
                if(symbol !='NCC' && symbol !='NCP'){
                    this.$router.push({path : `/${this.$i18n.locale}/CoinHistory/${symbol}`})
                }else{
                    if(symbol =='NCP'){
                        this.$router.push({path : `/${this.$i18n.locale}/AssetsHistory`})
                    }else if(symbol =='NCC'){
                        this.$router.push({path : `/${this.$i18n.locale}/NccAssetsList`})
                    }
                    
                }  
            },
            GotoPackagePage(){
                this.$router.push({path : `/${this.$i18n.locale}/Membership`})
            },
            GotoExchangePage(){
                this.$router.push({path : `/${this.$i18n.locale}/Transfer`})
            },
            
            CheckBalance(a,b){
                const p = exactMath.mul(a,b);

                return p

            },
            GetCoinList(){
                
                const body = {};
                const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
                this.$http.post(`/member/coin/GetCoinList`,{req}).then(
                    res => {
                        if(res.status==200){
                            if(res.data.code=='200'){
                                const e_body = res.data.body;
                                const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                                const d_res = bytes.toString(CryptoJS.enc.Utf8);
                                const body = JSON.parse(d_res)

                                this.list = body.list;

                                for (const [index,el] of body.list.entries()) {
                                    if(el.symbol !='NCC' && el.symbol !='NCP'){

                                        this.GetCoinBalance(index,el.symbol);    
                                    }
                                    
                                }
                            }else if(res.data.code=='9999'){
                                this.$store.dispatch('SETLOGOUT').then(
                                    ()=>{
                                        window.location.href= `/${this.$i18n.locale}/login`;
                                    }
                                )
                            }
                        }
                    }
                ).catch(() => {});
            },
            GetCoinBalance(index,symbol){
                
                const body = {symbol};
                const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
                this.$http.post(`/member/coin/GetCoinBalance`,{req}).then(
                    res => {
                        if(res.status==200){
                            if(res.data.code=='200'){
                                const e_body = res.data.body;
                                const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                                const d_res = bytes.toString(CryptoJS.enc.Utf8);
                                const body = JSON.parse(d_res)

                                this.list[index].balance = body.balance;
                            }else if(res.data.code=='9999'){
                                this.$store.dispatch('SETLOGOUT').then(
                                    ()=>{
                                        window.location.href= `/${this.$i18n.locale}/login`;
                                    }
                                )
                            }
                        }
                    }
                ).catch(() => {});
            },
            rateCheck(symbol){
                const rate = this.rate;
                for(var i=0; i<rate.length; i++){
                    if(symbol == rate[i].symbol) return rate[i].rate;
                } 
            },
            usdCalc(symbol, value){
                const rate = this.rate;
                for(var i=0; i<rate.length; i++){
                    if(symbol == rate[i].symbol) { const str = String((parseFloat(value) * parseFloat(rate[i].usd)).toFixed(2));
                    const price = str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,'); return price; 
                    }
                }
            },
		}
	}
</script>
<style>
</style>