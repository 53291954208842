<template>
	<div class="section_right">
		<div class="sub_top"></div>
		<div class="section_right_inner" v-if="address !=''">
			<div class="s_left text-center">
                <h6 class="text-center mt-4 mb-5 fw-bold flex-center-center "><img :src="img" :alt="symbol" width="22" class="me-2"> {{symbol}} {{$t("coin.5")}}</h6>
    
                <div class="qrcode_box mb-4" >
                    <vue-qrcode :value="address" style='width:150px'/>
                </div>
    
                <p class="font-weight-bold text-center break-all">{{address}}</p>
			</div>
            <div class="position-absolute start-0 bottom_bar w-100">
                <div class="flex-between-center gap-2 w-100 px-3 py-3">
                    <button class="border-0 w-100 fs-px-14 py-2 rounded" @click="Copy(address)"><i class="far fa-copy me-2"></i>{{$t('dashboard.7')}}</button>
                </div>
            </div>
		</div>
	</div>
</template>
<script>
const CryptoJS = require("crypto-js");
import VueQrcode from 'vue-qrcode'
	export default {
		components: {
			VueQrcode,
		},
		data(){
			return{
                img : '',
                symbol: this.$route.params.symbol,
                address: '',
			}
		},
	    mounted(){
			this.GetCoinReceive()
		},
		methods : {
			GetCoinReceive(){
                const symbol = this.symbol;
                const body = {symbol};
                const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
                this.$http.post(`/member/coin/GetCoinReceive`,{req}).then(
                    res => {
                        if(res.status==200){
                            if(res.data.code=='200'){
                                const e_body = res.data.body;
                                const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                                const d_res = bytes.toString(CryptoJS.enc.Utf8);
                                const body = JSON.parse(d_res)
                                this.img = body.info.img;
                                this.name = body.info.name;
								this.address  = body.address;
                            }else if(res.data.code=='9999'){
                                this.$store.dispatch('SETLOGOUT').then(
                                    ()=>{
                                        window.location.href= `/${this.$i18n.locale}/login`;
                                    }
                                )
                            }
                        }
                    }
                ).catch(() => {});
            },
            Copy(txt){
				this.$copyText(txt).then(()=> {
						alert(this.$t('deposit.8'))
					}	
				)
            }
		}
	}
</script>
<style scoped>
.theme01 .section_right_inner{
    color: #fff
}

</style>